<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <apexchart :options="this.options" :series="this.series"
                   type="line" v-if="this.series != null && this.options != null"></apexchart>
      </v-col>

      <v-col cols="12">
        <v-data-table
            :headers="this.headers"
            :items="this.UMSATZCCASHIERTABLE"
            :sort-by="['date']"
            :sort-desc="[false, true]"
            class="elevation-0"
            multi-sort
            v-if="this.UMSATZCCASHIERTABLE != null"
        >

          <template v-slot:item.umsatz="{ item }">
            {{ item.umsatz | currency }}
          </template>

          <template v-slot:item.solds="{ item }">
            {{ item.solds | currency }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import VueApexCharts from 'vue-apexcharts'
var self=null;
export default {
  name: "UmsatzWareGroupComponent",
  components: {
    'apexchart': VueApexCharts
  },
  data: () => {
    return {
      ENDPOINTS,
      options: null,
      series: null,
      UMSATZCCASHIERTABLE: null
    }
  },
  mounted() {
    this.loadData();
    self=this;
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('settings.lang_operator'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: this.$t('accounting.lang_turnover'), value: 'umsatz'},
        {text: this.$t('generic.lang_sales'), value: 'fat'},
        {text: this.$t('accounting.lang_turnover') + '/' + this.$t('generic.lang_Verkauf'), value: 'solds'},
        {
          text: this.$t('accounting.lang_returns'), value: 'retouren'
        }
        ,
      ]
    }
  },
  methods: {
    loadData() {
      const chart = this.axios.post(ENDPOINTS.ACCOUNTING.MONTHLYREPORT.UMSATZCASHIER, {
            reportID: this.$route.params.id
          }),
          table = this.axios.post(ENDPOINTS.ACCOUNTING.MONTHLYREPORT.UMSATZCASHIERTABLE, {
            reportID: this.$route.params.id
          });

      Promise.all([chart, table]).then(this.axios.spread((...res) => {
        this.UMSATZCCASHIERTABLE = res[1].data.data;

        if (res[0].data && res[0].data.success) {

          this.series = res[0].data.data;
          this.options = {
            chart: {
              type: 'line'
            },
            theme: {
              mode: this.$vuetify.theme.dark ? 'dark' : 'light',
            },
            yaxis: [
              {
                title: {
                  text: this.$t('generic.lang_totalInEuro')
                },
                labels: {
                  formatter: function (val) {
                    return self.$options.filters.currency(val);
                  }
                }
              },
            ],
            xaxis:
                {
                  title: {
                    text: this.$t('settings.lang_operator')
                  },
                  categories: res[0].data.labels,
                  labels: {
                    formatter: function (val) {
                      return val + "h"
                    }
                  }
                }
          };

        }


      }));
    },
  }

}
</script>

<style scoped>

</style>